import {controller} from '@github/catalyst'
import {dialog} from '@github-ui/details-dialog'

interface InputDemuxContext {
  onItemSelected(event: MouseEvent): void
}

interface ContextMap {
  baseChange: InputDemuxContext
}

class InputDemuxBaseChangeContext implements InputDemuxContext {
  onItemSelected(event: MouseEvent) {
    if (!(event.currentTarget instanceof HTMLButtonElement)) return
    const selectedRef = event.currentTarget.querySelector<HTMLSpanElement>('.js-ref-name')!.textContent
    const input = document.querySelector<HTMLInputElement>('.js-new-base-branch')!
    input.value = btoa(unescape(encodeURIComponent(selectedRef || '')))

    const content = document.querySelector<HTMLTemplateElement>('.js-change-base-template')!.content.cloneNode(true)
    if (!(content instanceof DocumentFragment)) return
    dialog({content})
  }
}

@controller
class InputDemuxContextWrapperElement extends HTMLElement {
  declare context: InputDemuxContext

  connectedCallback() {
    const contextType = this.getAttribute('data-context-type')
    const contexts: ContextMap = {baseChange: new InputDemuxBaseChangeContext()}
    this.context = contexts[contextType as keyof ContextMap]
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onItemSelected(event: MouseEvent) {
    this.context.onItemSelected(event)
  }
}
